.route__createWebsite__assetManager {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr auto;
    padding-top: 10px;
    height: 80vh;

    & * {
        flex-grow: 0;
        flex-shrink: 0;
    }
    &__left {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 45px calc(100% - 65px);
        grid-template-columns: 1fr;
        min-height: 0;

        &__search {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__list {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: max-content;
            grid-auto-rows: max-content;
            grid-template-columns: repeat(auto-fit, 100px);
            justify-content: center;
            row-gap: 20px;
            column-gap: 20px;
            margin-top: 20px;
            overflow: auto;
            position: relative;

            &__noData {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: gray;
            }
            &__img {
                width: 100%;
                height: auto;
                max-height: 100px;
                border: 3px solid transparent;
                border-collapse: collapse;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: border-color 0.3s ease;
                padding: 5px;

                & > img {
                    width: 100%;
                    height: auto;
                    min-height: 80px;
                    max-height: 100px;
                }
                &--active {
                    border-color: rgb(63, 124, 234);
                }
            }
        }
    }
    &__right {
        overflow: hidden;
        width: 0px;
        height: auto;
        margin-left: 0;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        transition: width 0.3s ease, margin-left 0.3s ease;

        &--active {
            width: 250px;
            margin-left: 20px;
        }
        & > img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100px;
        }
        & > p {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 60px 1fr;
            margin: 10px 0;
            min-width: 250px;
            overflow-x: hidden;

            & > span:last-child {
                color: gray;
            }
        }
        &__assetSelect {
            margin-left: auto !important;
        }
    }
}