.components__customComponents__checkbox {
    all: initial;
    display: inline-flex;
    width: auto;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    
    &__check {
        width: 60px;
        height: 30px;
        border-radius: 999px;
        border: 1px solid gray;
        margin-right: 10px;
        position: relative;
        overflow: hidden;

        &__circle {
            background-color: gray;
            border: 1px solid gray;
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            transition: left 0.3s ease, background-color 0.3s ease;

            &--active {
                left: calc(100% - 30px);
            }
        }
    }
}
.components__customComponents__checkbox--dark {
    color: white;
}