.route__commentsList {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 60px;

  .route__commentsList__menu{
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    width: 100%;
    .route__commentsList__offers{
      overflow: hidden;
      max-height: 42px;
      cursor: pointer;
      top: 0;
      left: 0;
      z-index: 999;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      padding: 12px 8px;
      border: 1px solid gray;
      background-color: #2F363E;
      border-radius: 5px;
      transition: max-height 0.3s ease;
      &__head{
        border-radius: 5px;
        min-width: 50px;
        text-align: center;
        cursor: pointer;
        &>span{
          color: green;
        }
      }
      &__itemList{
        width: 100%;
        height: 100%;
        padding: 0px 16px;
        max-height: 0px;
        overflow: scroll;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: 0.3s ease;
        .customComponents__input{
          margin-top: 10px;
        }
      }
      &__item{
        padding: 4px;
        text-align: center;
        transition: 0.3s ease;
        border-radius: 5px;
        margin: 0px 5px;
        min-width: 50px;
        text-align: center;
        cursor: pointer;
        &:hover{
          color: green;
        }
      }
    }
  
  }

  &__createComment {
    all: initial;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    cursor: default;

    &__wrap {
      width: 560px;
      height: auto;
      background: #282F37;
      border-radius: 10px;
      padding: 20px 18px;
      position: relative;

      &__selectComms{
        position: absolute;
        top: 10px;
        right: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding: 12px 8px;
        border: 1px solid gray;
        background-color: #2F363E;
        border-radius: 5px;
        cursor: pointer;
      }

      &__aiHelper{
        display: flex;
        gap: 20px;

        &>p{
          cursor: pointer;
        }
      }

      & > textarea{
        border: none;
        outline: none;
        color: #fff;
        border-bottom: 1px solid #fff;
        width: 100%;
        padding: 0 5px;
        margin-bottom: 20px;
        height: calc(100% - 1px);
        font-size: 16px;
        display: block;
        background-color: transparent;
        font-family: "Montserrat";
        overflow: hidden;
        resize: none;

        &.route__commentsList__createComment__wrap__textarea {
          width: 100%;
          height: 200px;
          background-color: #232930;
          border: 1px solid gray;
          padding: 10px;
        }
      }
      &__builderButtons {
        display: flex;
        align-items: center;
        gap: 10px;

        & > button {
          padding: 5px;
          border: 1px solid gray;
          outline: none;
          background-color: #232930;
          color: white;

          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #30373f;
          }
        }
      }
      &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #282f37c6;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
        z-index: 9;
      }

      &__btns {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &>p {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: unset;
            right: 0;
            width: 0%;
            height: 2px;
            background-color: #2c7de3;
            transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
          }

          &:last-child::after {
            background-color: #EA3F3F;
          }

          &:hover::after {
            right: unset;
            left: 0;
            width: 100%;
          }

          &>span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      &__likes{
        display: flex;
        justify-content: space-between;
      }

      &__image{
        display: flex;
        justify-content: space-between;
        &>p{
          width: 100%;
          text-align: center;
          padding: 10px;
          margin-bottom: 0px !important;
          cursor: pointer;
        }
      }


      &__infoP {
        color: rgb(245, 74, 74);
        margin-top: 10px;
      }
    }
  }
}

.route__commentsList__templateSettings{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 1000;
  &__wrapper{
    width: 90%;
    height: auto;
    background: #282F37;
    border-radius: 10px;
    padding: 20px 18px;
    position: relative;
    &__close{
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 4px 8px;
      border: 1px solid #de0202;
      border-radius: 5px;
      color: #de0202;
      cursor: pointer;
    }
    &__head{
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      &__item{
        display: flex;
        gap: 10px;
        padding: 12px 16px;
        border: 1px solid gray;
        border-radius: 5px;
        .route__commentsList__offers{
          overflow: hidden;
          max-height: 42px;
          height: auto;
          transition: max-height 0.3s ease;
          &__head{
            border-radius: 5px;
            min-width: 50px;
            text-align: center;
            cursor: pointer;
            &>span{
              color: green;
            }
          }
          &__itemList{
            width: 100%;
            height: 100%;
            padding: 0px 16px;
            max-height: 0px;
            overflow: scroll;
            display: flex;
            align-items: center;
            flex-direction: column;
            background-color: #2F363E;
            transition: 0.3s ease;
            .route__commentsList__offers__item{
              cursor: pointer;
              transition: 0.3s ease;
              &:hover{
                color: green;
              }
            }
          }
        }
      }
    }
  }
}

.route__commentsList__viewReplies{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 1000;
  &__wrapper{
    width: 90%;
    height: auto;
    background: #282F37;
    border-radius: 10px;
    padding: 40px 18px;
    position: relative;
  }
}

.create__template{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 1000;
  &__wrapper{
    width: 90%;
    max-width: 500px;
    height: auto;
    background: #282F37;
    border-radius: 10px;
    padding: 20px 18px;
    position: relative;
  }
}


.route__commentsList__multiselect{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 999;
  overflow: hidden;
  transition: 0.4s ease;
  &__inner{
    width: 100%;
    max-width: 1200px;
    background: #282F37;
    height: max-content;
    max-height: 780px;
    border-radius: 4px;
    padding: 10px;
    position: relative;
    &__genderBtns{
      position: absolute;
      top: -50px;
      display: flex;
      gap: 18px;
      &>p{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid;
        border-color: #fff;
        min-width: 70px;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        transition: 0.3s ease;
        cursor: pointer;
      }
    }
    &__suggestedHeadline{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 6px;
    }
    &__data{
      width: 100%;
      height: 100%;
      max-height: 700px;
      overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      &>div{
        padding: 10px;
        text-align: center;
        transition: 0.3s ease;
        height: auto;
        // &:hover {
        //   box-shadow: inset 0 0 10px 2px rgba(0, 0, 255, 1);
        // }
      }
      .data--item--selected{
        border-color: #00a30e !important;
        color: #00a30e !important;
      }
    }
    
  }
}