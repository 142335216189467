.route__copywriterList {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 20px;
    justify-items: center;

    &.cloned-element{
        overflow: scroll;
    }

    &__item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;
        border: 1px solid #ddd; /* Lighter border for a softer look */
        border-radius: 12px; /* Slightly rounded corners */
        width: max-content;
        cursor: pointer;
        overflow: scroll !important; /* Adjusted to only scroll vertically */
        background-color: rgba($color: #000000, $alpha: 0.2); /* Light background for better readability */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        transition: all 0.3s ease; 

        &:hover{
            border-color: #007bff; /* Change border color on hover */
            background-color: rgba($color: #000000, $alpha: 0.35); /* Slightly darker background on hover */
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
        }

        .route__landingList__copywritersInfo{
            height: auto;
            min-height: 100%;
            &--data{
                height: auto;
                min-height: 100%;
                padding: 20px;
                display: grid;
                grid-template-rows: auto 560px;
                grid-template-columns: repeat(auto-fit, 350px);
                row-gap: 30px;
                column-gap: 30px;
                overflow-y: scroll;
            }
        }
    }
    
}
