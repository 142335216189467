.route__landingList {
    width: 100%;
    height: auto;

    &--filters {
        margin-bottom: 20px;
        &__advanced{
            display: flex;
            gap: 20px;
            .customComponents__input{
                max-width: 360px;
            }
        }
    }
    &--data {
        display: grid;
        grid-template-rows: auto 560px;
        grid-template-columns: repeat(auto-fit, 352px);
        row-gap: 30px;
        column-gap: 30px;
    }
    &__menu{
        display: flex;
        gap: 20px;
        margin-bottom: 10px;
        &>p{
            cursor: pointer;
            padding: 10px;
            border: 1px solid #fff;
            border-radius: 10px;
            box-shadow: 0 0px 0px rgba(255, 255, 255, 0.2); // Default box shadow
            transition: box-shadow 0.3s ease, transform 0.3s ease; // Smooth transition for box shadow and transform

            &.active {
                box-shadow: inset 0 4px 8px rgba(255, 255, 255, 0.3); // Inset box shadow for active state
                transform: scale(1.02); // Slightly scale up for a subtle effect
            }
        }
    }
    &__item {
        width: 100%;
        height: 100%;
        background: #2F363E;
        border-radius: 10px;
        padding: 20px 18px;
        position: relative;
        cursor: pointer;
        transition: box-shadow 0.3s ease;
        
        &:hover {
            box-shadow: 0px 0px 9px 2px rgba(63,124,234,0.75);
        }
        &__id {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
        }
        &__img {
            width: 315px;
            height: 277px;
            border: none;
            outline: none;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            & > img {
                width: 100%;
                height: 100%;
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #2F363E;
                transition: background-color 0.3s ease;

                & > p {
                    margin-top: 10px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        &__name {
            border-top: 1px solid #48525C;
            border-bottom: 1px solid #48525C;
            padding: 16px 0px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 16px;
            &>input{
                background-color: transparent;
                border: none;
                outline: none;
                border-bottom: 1px solid #48525C;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #fff;
                margin-bottom: 6px;
                max-width: 220px;
            }
            &__pencil{
                width: 16px;
                margin: 0 8px;
            }
            &__icon{
                width: 20px;
                margin: 0 4px;
            }
        }
        &__btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 5px;
        }
    }

    &__delete {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        cursor: default;

        &__wrap {
            width: 300px;
            height: auto;
            background: #282F37;
            border-radius: 10px;
            padding: 20px 18px;
            position: relative;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #282f37c6;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                z-index: 9;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}


.landerInfo{
    height: 100%;
    padding: 20px;
    background-color: rgba($color: #000000, $alpha: 0.8);

    &__closeBtn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        top: 10px;
        position: absolute;
        right: 20px;
        width: 40px;
        padding: 0px 30px;
        border-radius: 5px;
        background-color: rgba($color: #000000, $alpha: 0.7);
        z-index: 100;
    }

    &__headline{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
    }

    &__itemsContainer{
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}