.route__createWebiste {
    grid-column: 1 / span all;
    width: 100%;
    height: 100%;
    
    &__inner {
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;

        .gjs-one-bg {
            background-color: #2F363E;
        }
        .gjs-three-bg {
            background-color: #3F7CEA;
            color: white;
        }
        .gjs-four-color,
        .gjs-four-color-h:hover {
            color: #3F7CEA;
        }
        .gjs-two-color {
            color: white;
        }
        .gjs-rte-action {
            width: auto;
        }
        .code-panel {
            text-align: left;
        }
        .gjs-block svg {
            max-width: 100%;
        }
        .gjs-toolbar {
            background-color: #2f363e;
            border-radius: 5px;
            padding: 3px 10px;
            box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.75);
        }
        .gjs-toolbar-item {
            margin: 0px 5px;

            & svg, & svg * {
                transition: fill 0.3s ease;
            }
            &:hover {                
                & svg, & svg * {
                    fill: #3F7CEA !important;
                }
            }
        }
    }
}
.route__createWebisteDialog {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__wrap {
        width: 500px;
        height: auto;  
        padding: 20px;
        background-color: #282F37;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        position: relative;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            background-color: #282f37aa;
            z-index: 2;
        }
        &__btns {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            & > p {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: unset;
                    right: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #2c7de3;
                    transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                }
                &:last-child::after {
                    background-color: #EA3F3F;
                }
                &:hover::after {
                    right: unset;
                    left: 0;
                    width: 100%;
                }
                & > span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        &__choices{
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 20px;
            &>p{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;
                padding: 10px 25px;
                border: 1px solid white;
                border-radius: 10px;
                box-shadow: 0 0 2px rgb(255, 255, 255), 0 0 4px rgb(255, 255, 255);
                transition: box-shadow 0.3s ease, background-color 0.2s ease;

                &:hover {
                    box-shadow: inset 0 0 4px rgb(255, 255, 255), inset 0 0 8px rgb(255, 255, 255);
                    background-color: rgba(255, 255, 255, 0.05); 
                }
            }

            &>p.selected {
                color: rgb(137, 248, 137);
                box-shadow: inset 0 0 4px rgb(137, 248, 137), inset 0 0 8px rgb(137, 248, 137);
                border: 1px solid rgb(137, 248, 137);
            }
        }
        &__infoP {
            color: #EA3F3F;
            margin-top: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.route__openTemplateModal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &__wrap {
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: #282F37;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        position: relative;
        display: grid;
        grid-template-rows: 23px 20px calc(100% - 42px);
        grid-template-columns: auto;
        align-items: center;
        row-gap: 20px;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            background-color: #282f37aa;
            z-index: 2;
        }
        &__templates {
            padding: 10px;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            display: grid;
            grid-template-rows: auto 520px;
            grid-template-columns: repeat(auto-fit, 352px);
            row-gap: 20px;
            column-gap: 20px;
        }
        &__heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
        &__btns {
            width: auto;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > p {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: unset;
                    right: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #2c7de3;
                    transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                }
                &:last-child::after {
                    background-color: #EA3F3F;
                }
                &:hover::after {
                    right: unset;
                    left: 0;
                    width: 100%;
                }
                & > span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        &__infoP {
            color: #EA3F3F;
            margin-top: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }
    }
}