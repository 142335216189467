.route__openAiUsage {
    width: 100%;
    height: auto;
    .route__openAiUsage__headline{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 19px;
        margin-bottom: 10px;
    }
}

.customComponents__table__filterHead{
    padding-top: 0px;
    padding-bottom: 0px;
}
