.component__sidebar {
    font-family: "Inter";
    width: 100%;
    height: 100%;
    background-color: #282F37;
    padding: 0 20px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;

    &__item, &__itemCollapsed {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 12px 16px;
        border: 1px solid transparent;
        border-radius: 5px;
        background-color: transparent;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        &:hover {
            border-color: #48525C;
        }
        & > img {
            margin-right: 12px;
            max-width: 20px;
            height: auto;
        }
        &--active {
            border-color: #48525C;
            background-color: #2F363E;
        }
    }
    &__itemCollapsed {
        max-height: 46px;
        transition: max-height 0.3s ease, border 0.3s ease;
        overflow: hidden;
        flex-wrap: wrap;

        &--active {
            background-color: transparent;
            max-height: 500px;
            overflow: scroll;
        }
        &--active:hover {
            border-color: none;
        }
        &__arrow {
            transform: rotate(-90deg);
            transform-origin: center center;
            transition: transform 0.3s ease;
        }
        &--active &__arrow {
            transform: rotate(0deg);
        }
        &__children {
            flex-basis: 100%;
            padding: 10px 10px;
            padding-bottom: 0;

            & > * {
                margin: 6px 0px;
            }
        }
    }
    &__userInfo {
        margin-top: auto;
        margin-bottom: 36px;
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 20px;
        background-color: #2F363E;
        border: 1px solid #48525C;
        border-radius: 5px;
        position: relative;

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #282f37c6;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
            z-index: 9;
        }
        &__circle {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #3F7CEA;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__name {
            margin-left: 20px;
        }
        &__moreInfo {
            margin-left: auto;
        }
    }
    &__modal {
        padding: 12px;
        background: #2F363E;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.39);
        border-radius: 5px;
        position: fixed;
        top: 0;
        left: 0;
        width: 205px;
        height: auto;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-100%);
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        transition: opacity 0.3s ease;

        & > p {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            position: relative;
            width: 100%;
            padding: 5px 0px;

            & > img {
                width: 16px;
                height: 16px;
                margin-right: 12px;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: unset;
                right: 0;
                width: 0%;
                height: 2px;
                background-color: #3F7CEA;
                transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
            }
            &:hover::after {
                left: 0;
                width: 100%;
            }
        }
    }
    &__changePassword {
        all: initial;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        cursor: default;

        &__wrap {
            width: 500px;
            height: auto;
            background: #282F37;
            border-radius: 10px;
            padding: 20px 18px;
            position: relative;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #282f37c6;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                z-index: 9;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &__infoP {
                color: rgb(245, 74, 74);
                margin-top: 10px;
            }
        }
    }
}