.route__createWebsite__blockManager{
    padding-top: 20px;
}

.route__createWebsite__blockManager__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .route__createWebsite__blockManager__block{

        &>p{
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 10px 20px;
            background-color: rgba(0,0,0,.1);
            border-bottom: 1px solid rgba(0,0,0,.25);
            margin-bottom: 4px;
            .gjs-caret-icon{
                min-width: 7px;
            }
        }
        width: 100%;
        max-height: 40px;
        overflow: hidden;
        cursor: pointer;
        transition: 0.3s ease;

        .route__createWebsite__blockManager__block__elements{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 8px;
            gap: 10px;
            padding: 10px;
            .route__createWebsite__blockManager__block__elements__element{
                border: 1px solid rgba(187, 184, 184, 0.2);
                border-radius: 3px;
                box-shadow: 0 1px 0 0 rgba(243, 243, 243, 0.15);
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-size: 11px;
                font-weight: lighter;
                justify-content: space-between;
                min-height: 90px;
                min-width: 45px;
                padding: 1em;
                text-align: center;
                width: 100%;
                transition: background-color 0.4s ease;
                svg {
                    fill: white !important;
                    max-width: 100% !important;
                    max-height: 120px !important;
                    height: auto !important;
                }
                & > * {
                    pointer-events: none;
                }
            }
            .route__createWebsite__blockManager__block__elements__element:hover{
                background-color: rgba(255, 255, 255, 0.1);
                box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
            }
        }
    }

    .block-open{
        max-height: 10000px;
    }
}