.route__campaignsList {
    width: 100%;
    height: auto;
    overflow: auto;
    font-family: "Inter";
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    row-gap: 20px;

    &__dateFilter {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__head {
            margin-right: 10px;
        }
        & > div {
            border-radius: 5px;
            border: 1px solid gray;
            margin: 0px 5px;
            min-width: 50px;
            text-align: center;
            cursor: pointer;
            transition: color 0.3s ease, border-color 0.3s ease;

            & > span {
                display: block;
                margin: 5px 10px;
            }
        }
        &__item--active {
            color: orange;
            border-color: orange !important;
        }
        &__item--active.route__campaignsList__dateFilter__item--blue {
            color: #2c7de3;
            border-color: #2c7de3 !important;
        }
    }
    &__item {
        width: 100%;
        height: auto;
        padding: 20px;
        background-color: #282F37;
        border: 1px solid #8080803d;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        position: relative;
        .badgeImage{
            position: absolute;
            width: 32px;
            height: 32px;
            bottom: 4px;
            left: 4px;
        }

        &__row {
            width: 100%;
            height: auto;
            display: grid;
            grid-template-columns: 1fr repeat(3, 150px);
            place-items: center;
            grid-template-rows: auto;
            column-gap: 20px;

            & > p {
                width: 100%;
                height: auto;
            }
            & > p:not(:first-child) {
                text-align: center;
            }
            &__actions {
                width: 100%;
                height: auto;
                display: grid;
                grid-template-rows: repeat(auto-fit, 20px);
                grid-template-columns: repeat(auto-fit, 20px);
                grid-auto-flow: column;
                row-gap: 20px;
                column-gap: 20px;
                justify-content: flex-end;
                align-items: center;

                & > p {
                    cursor: pointer;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -2px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: width 0.3s ease, left 0.3s ease, right 0.3s ease;
                    }
                    &:hover::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }
            &--inset {
                margin-top: 10px;
                padding-left: 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
            &--inset2 {
                color: #7F8993;
                padding-left: 35px;
            }
        }
    }
}

.route__campaignsList__addCampaign {
    all: initial;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    user-select: none;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 27px;

    & .component__customComponent__dropbox__placeholder{
        background-color: #282f37;
        padding: 0 5px;
    }
    &__wrap {
        position: relative;
        width: 600px;
        height: auto;
        max-height: 100%;
        overflow-y: auto;
        padding: 30px;
        background-color: #282F37;

        &__website {
            margin: 5px 0;
            display: flex;
            align-items: center;
            gap: 10px;
            padding-left: 15px;

            & > p {
                color: #7F8993;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
        }
        &__spinner {
            z-index: 99;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #282f3774;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
        &__dropdownElem {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
        }
        &__btns {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            & > p {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: unset;
                    right: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #2c7de3;
                    transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                }
                &:last-child::after {
                    background-color: #EA3F3F;
                }
                &:hover::after {
                    right: unset;
                    left: 0;
                    width: 100%;
                }
                & > span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        &__websiteItem {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            & > p {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: unset;
                    right: 0;
                    width: 0%;
                    height: 2px;
                    background-color: #2c7de3;
                    transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                }
                &:hover::after {
                    right: unset;
                    left: 0;
                    width: 100%;
                }
                & > span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        &__websiteList {
            padding-left: 15px;
            width: calc(100% - 5px);
            margin-left: 5px;
            height: auto;
            border-left: 2px solid gray;

            & > p {
                margin-top: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                & > span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #7F8993;
                }
                & > img {
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }
    }
}