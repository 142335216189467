.route__landerRequests {
    width: 100%;
    height: auto;

    &__kpi {
        margin: 20px 0;

        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(3, 1fr);

        &__item {
            padding: 24px 40px;
            border: 1px solid rgba(63, 123, 234, 0.507);
            background-color: #282F37;

            &:nth-child(1) {
                border-right: none;
            }
            &:nth-child(3) {
                border-left: none;
            }

            &__top {
                font-family: Inter;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 12px;
            }
            &__bottom {
                font-family: Poppins;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 1px;
                text-align: left;
            }
        }
    }
    &__url {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & > p {
            margin-right: 5px;
            color: #a4a4a4 !important;
        }
        & > span {
            padding: 4px 10px;
            border-radius: 5px;
            background-color: #3e4a58;
            font-size: 12px;
            margin-right: 5px;

            &.route__websiteRoutes__tablePill--secondary {
                background-color: #2a5e98;
            }
        }
    }
    &__addLanderRequest {
        all: initial;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        cursor: default;

        &__wrap {
            width: 500px;
            height: auto;
            background: #282F37;
            border-radius: 10px;
            padding: 20px 18px;
            position: relative;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #282f37c6;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                z-index: 9;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &__infoP {
                color: rgb(245, 74, 74);
                margin-top: 10px;
            }
        }
    }
}