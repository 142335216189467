.component__header {
    width: 100%;
    height: 100%;
    background-color: #2F363E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 38px;

    &__left {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        & > h3 {
            margin-left: -20px;
        }
    }
    &__right {
        display: flex;
        flex-direction: row;

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            margin-left: 20px;

            &::after {
                content: '';
                position: absolute;
                bottom: -6px;
                left: unset;
                right: 0;
                width: 0%;
                height: 2px;
                background-color: #2c7de3;
                transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
            }
            &--red::after {
                background-color: #EA3F3F;
            }
            &--orange::after {
                background-color: orange;
            }
            &:hover::after {
                right: unset;
                left: 0;
                width: 100%;
            }
            & > img {
                width: 30px;
                height: 30px;
                margin-right: 14px;
            }
            & > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
        }
        .component__header__right__btn--closed{
            opacity: 0.2;
        }
    }
}