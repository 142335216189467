.route__imagesList {
    &>.fromComments__closeBtn{
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border: 1px solid #fa5a5a;
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 10px;
        cursor: pointer;
        z-index: 10;
        transition: 0.3s ease;
        &:hover{
            box-shadow: inset 0 0 10px #fa5a5a;
        }
    }
    &__filter {
        margin-bottom: 20px;
    }
    &__wrap {
        width: 100%;
        height: calc(100vh - 100px);
        display: grid;
        grid-template-rows: 300px;
        grid-auto-rows: 300px;
        grid-template-columns: repeat(auto-fit, 300px);
        row-gap: 30px;
        column-gap: 30px;

        overflow-y: scroll;
    
        color: white;
        font-family: "Inter";
    
        &__image {
            width: 100%;
            height: 100%;
            background: #2F363E;
            border-radius: 10px;
            padding: 20px 18px;
            position: relative;
            cursor: pointer;
            transition: box-shadow 0.3s ease;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            &__img {
                width: 100%;
                height: calc(100% - 100px);
                justify-self: center;
                align-self: center;
                display: flex;
                justify-content: center;
                align-items: center;
    
                & > img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            & > p {
                width: 100%;
                text-align: center;
                border-top: 1px solid #48525C;
                border-bottom: 1px solid #48525C;
                padding: 5px 0px;
            }
            &:hover {
                box-shadow: 0px 0px 9px 2px rgba(63,124,234,0.75);
            }
            &__btns {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                gap: 10px;
            }
        }
    }
    &__renameImage {
        all: initial;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: "Inter";

        &__wrap {
            width: 500px;
            height: auto;
            padding: 20px;
            background-color: #282F37;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            position: relative;

            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                background-color: #282f37aa;
                z-index: 2;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &__infoP {
                color: #EA3F3F;
                margin-top: 10px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
            &__selectImage {
                width: 200px;
                height: 200px;
                border: 1px solid gray;
                margin: 0 auto;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &__wrap {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 99;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.696);
                    transition: opacity 0.3s ease;
                }
                &:hover > &__wrap {
                    opacity: 1;
                }
                & > img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}