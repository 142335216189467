.route__documentation{
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Inter";
  font-style: normal;
  width: 100%;
  max-width: 700px;
}

.route__documentation__headline{
  font-weight: 500;
  font-size: 28px;
  line-height: 30px;
  text-decoration: underline;
}

.route__documentation__section-headline{
  font-weight: 500;
  font-size: 26px;
  line-height: 28px;
  text-decoration: underline;
  margin-top: 10px;
}


.route__documentation__desc{
  display: flex;
  flex-direction: column;
  gap: 6px;

  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.route__documentation__introBlock{
  display: flex;
  gap: 10px;
  padding: 10px;

  &__left{
    &>img{
      max-width: 100%;
      height: auto;
    }
  }

  &__right{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.flex-column{
  flex-direction: column;
}