@keyframes customTableDataTransitionOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes delay-overflow {
    from {
        overflow: hidden;
    }
}
.customComponents__table {
    all: initial;
    display: block;
    border-radius: 5px;
    width: max-content;
    min-width: 100%;
    opacity: 0;
    font-size: initial !important;
    font-weight: initial !important;
    color: black;
    font-family: "Inter";
    position: relative;

    background: #242731;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    display: grid;
    grid-template-rows: auto;
    overflow-y: clip;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(35, 38, 48, 0.8);
        z-index: 999;

        transition: opacity 0.3s ease;

        & .customComponents__spinner {
            position: absolute;
            top: MIN(50vh, 50%);
            left: MIN(50vw, 50%);
            transform: translateX(-50%) translateY(-50%);
        }
    }
    &__filterHead {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #ffffff;
        color: black;
        padding: 20px 40px;
        margin-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;

        &__filterList {
            display: flex;
            flex-wrap: wrap;
            color: white;

            & > span {
                width: auto;
                overflow: hidden;
                max-width: 0;
                padding: 0;
                background-color: white;
            }

            &__addFilter {
                background-color: transparent !important;
                max-width: 100% !important;
                color: #5f6470;
                font-family: Inter;
                margin-right: 10px;
                margin-top: 5px;
                margin-bottom: 5px;
                padding: 10px 5px;
                border-radius: 7px !important;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 40px;
                cursor: default;
                transition: background-color 0.3s ease;

                & > img {
                    filter: invert(1);
                }

                &:hover {
                    background-color: #dededf !important;
                }
            }
        }

        &__filterList > span {
            font-family: Inter;
            cursor: pointer;
            border-radius: 5px;
            height: 100%;
            padding: 10px 0;
            display: inline-block;
            transition: background-color 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #5f6470;
            overflow: hidden;
            white-space: nowrap;
            max-width: 0;

            &:hover {
                background-color: #caccf5;
            }
            & span:nth-child(2) {
                margin-left: 5px;
            }
        }
        &__addFilterWrap {
            width: 100%;
            max-height: 0;
            height: auto;
            margin-top: 0px;
            overflow: hidden;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            background-color: #ffffff;
            transition: max-height 0.3s ease, padding 0.3s ease;
            padding-left: 40px;

            &--active {
                max-height: 100px;
                overflow: initial;
                padding-top: 4px;
                padding-bottom: 4px;
                animation: 1s delay-overflow;
                @media screen and (max-width: 700px) {
                    max-height: 150px;
                }
            }
            div {
                margin-right: 30px;
            }
        }
    }
    &__head {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        // place-items: start stretch;
        grid-auto-flow: column;
        column-gap: 20px;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        padding: 0 40px;
        box-sizing: border-box;
        border-bottom: 1px solid #373A43;

        transition: opacity 0.3s ease;

        @media screen and (max-width: 600px) {
            display: none;
        }
        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            
            color: #FFFFFF;
            @media screen and (max-width: 1200px) {
                font-size: 14px;
            }
        }
        &__item {
            &--border {
                position: relative;
                min-height: 100%;

                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -25px;

                    width: 1px;
                    height: 100%;

                    background-color: #3f3f3f;
                }
                &:last-child::after {
                    display: none;
                }
            }
        }
        &__item__order {
            width: 25px;
            height: 25px;

            display: inline-flex;
            justify-content: center;
            align-items: center;

            margin-left: 5px;
            padding: 3px;
            border-radius: 50%;
            border: 1px solid white;
        }
    }
    &__data {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        place-items: start stretch;
        grid-auto-flow: column;
        column-gap: 20px;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        background-color: #efeff6;
        padding: 0 40px;
        box-sizing: border-box;

        &--footer {
            transition: opacity 0.3s ease;

            background-color: #4B4282 !important;
        }
        &--border > * {
            position: relative;
            min-height: 100%;

            &::after {
                content: '';
                position: absolute;
                top: -20px;
                right: -25px;

                width: 1px;
                height: calc(100% + 40px);

                background-color: #3f3f3f;
            }
            &:last-child::after {
                display: none;
            }
        }
        & > * {
            justify-self: center;
            grid-row: 1;
            width: 100%;
        }
        &__mobileFriendly {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 30px;

            & > * {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-end;
            }
        }
        &__mobile {
            font-weight: bold;
        }
        &__mobileButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 30px;
            flex-shrink: 0;
        }
        &__group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            & > * {
                margin: 5px;
                justify-self: center;
            }
        }
        &__groupNewline {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            margin: 5px;
            flex-wrap: wrap;
            grid-column: 1 / span all;

            & > * {
                margin: 5px;
                justify-self: center;
                flex-grow: 0;
                flex-shrink: 0;
            }
            @media screen and (max-width: 700px) {
                justify-content: flex-end;
                margin-top: 20px;
            }
        }
        &__dropdown {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: auto;
            align-items: center;
            background-color: transparent;
            box-sizing: border-box;
            max-height: 0vh;
            overflow: hidden;
            transition: max-height 1s ease;
        }
        p {
            //styleName: 12 Regular;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #13213C;
            word-break: break-all;
            max-width: 100%;
        }
        &__customInput {
            min-width: 100%;
            height: auto;
        }
    }
    &__data0 {
        background-color: #e4e4ec;
    }
}
.customComponents__table--dark.customComponents__table {
    color: white;

    & .customComponents__table__head,
    & .customComponents__table__filterHead {
        background-color: #242731;
        color: white;
        p {
            color: white;
        }
    }
    & .customComponents__table__filterHead {
        &__addFilterWrap {
            background-color: #242731;
        }
        &__filterList__addFilter {
            & > img {
                filter: invert(1);
            }
            &:hover {
                background-color: #3e4064 !important;
            }
        }
        &__filterList > span {
            color: white;
            background-color: #3f4251;
            &:hover {
                background-color: #3e4064;
            }
            & span:nth-child(2) {
                margin-left: 5px;
            }
        }
    }
    & .customComponents__table__data {
        background-color: #282F37;
        p {
            color: white;
        }
    }
    & .customComponents__table__data0 {
        background-color: #313943;
    }
}
