.route__translations {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  &__create {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    &__btn {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
      cursor: pointer;
      padding: 14px 20px;
      border-radius: 5px;
      border: 1px solid #48525C;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease;

      &:hover {
        box-shadow: inset 0px 0px 4px 0px #fff;
        font-size: 17px;
      }
    }
  }

  &__editTranslation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.3);

    &__wrap {
      width: 500px;
      height: auto;
      background: #282F37;
      border-radius: 10px;
      padding: 20px 18px;
      position: relative;

      &__btns {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &>p {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: unset;
            right: 0;
            width: 0%;
            height: 2px;
            background-color: #2c7de3;
            transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
          }

          &:last-child::after {
            background-color: #EA3F3F;
          }

          &:hover::after {
            right: unset;
            left: 0;
            width: 100%;
          }

          &>span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
}