.component__contentWrapper {
    width: 100%;
    height: 100%;
    overflow: auto;

    &--active {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 320px 1fr;
    }
    &.component__mainContent {
        padding: 40px 50px;
    }
}