.route__apiTokens {
    width: 100%;
    height: auto;

    &__flags {
        width: 100%;
        height: auto;


        display: flex;
        align-items: center;
        gap: 5px;

        & > span {
            font-size: 12px;
        }
        & > span:not(:first-child) {
            padding: 2px 5px;
            color: white;
            background-color: rgb(72, 81, 92);

            border-radius: 5px;
        }
    }
    &__addToken {
        all: initial;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        cursor: default;

        &__wrap {
            width: 500px;
            height: auto;
            background: #282F37;
            border-radius: 10px;
            padding: 20px 18px;
            position: relative;

            &__flags {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin: 10px 0;
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #282f37c6;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                z-index: 9;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &__infoP {
                color: rgb(245, 74, 74);
                margin-top: 10px;
            }
        }
    }
}