.component__customComponent__multiselect {
  width: auto;
  height: 45px;
  min-width: 136px;
  border: 1px solid #5A5B6A;
  box-sizing: border-box;
  border-radius: 4px;
  color: #19191D;
  display: block;
  position: relative;
  box-shadow: 0px 0px 2px #00A3FF, 0px 0px 4px #00A3FF;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  &__headline {
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    overflow: hidden;
    max-width: calc(100% - 30px);
    height: auto;
    word-break: keep-all;
    white-space: nowrap;
    margin: 0 auto;
  }

  &__popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: 999;
    overflow: hidden;
    transition: 0.4s ease;
    &__inner{
      width: 100%;
      max-width: 500px;
      background: #282F37;
      height: auto;
      border-radius: 4px;
      padding: 10px;
      &__suggestedHeadline{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 6px;
      }
      &__data{
        width: 100%;
        height: auto;
        max-height: 300px;
        overflow: scroll;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        &>div{
          padding: 10px;
          text-align: center;
          transition: 0.3s ease;
          // &:hover {
          //   box-shadow: inset 0 0 10px 2px rgba(0, 0, 255, 1);
          // }
        }
        .data--item--selected{
          border-color: #00a30e !important;
          color: #00a30e !important;
        }
      }
      
    }
  }

}



.component__customComponent__multiselect--dark {
  & .component__customComponent__multiselect {
    &__headline {
      color: white;
    }

    &__dropdown {
      background-color: #2B2D3E;
      color: white;

      &__searchBox {
        color: white;
        background-color: #2B2D3E;
      }
    }
  }
}