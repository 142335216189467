.route__creativeSingle {
    font-family: Inter;
    color: white;
    width: 100%;
    height: auto;

    &__top {
        background-color: #282F37;
        width: 100%;
        height: auto;
        padding: 20px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 30px;

        &__left {
            width: auto;
            min-width: 0;
            height: auto;

            & > img {
                width: auto;
                height: auto;
                max-width: 300px;
                max-height: 300px;
            }
        }
        &__right {
            min-width: 0;
            width: 100%;
            height: auto;

            &__btns {
                & > * {
                    margin-right: 10px;
                    margin-top: 10px;
                }
            }
        }
    }
    &__bottom {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: max-content;
        grid-auto-rows: max-content;
        row-gap: 10px;
        margin-top: 20px;

        &__itemAdd {
            background-color: #282F37;
            padding: 20px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #323b44;
            }
        }
        &__item {
            background-color: #282F37;
            padding: 20px;
            border-radius: 5px;

            &__top {
                white-space: pre-wrap;
                word-break: break-all;
                margin-bottom: 20px;
            }
        }
    }
    &__addText {
        all: initial;
        font-family: Inter;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;

        &__wrap {
            width: 600px;
            height: auto;
            padding: 20px;
            background: #2F363E;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            position: relative;

            & textarea {
                width: 100%;
                height: 300px;
                outline: none;
                border: 1px solid gray;
                resize: none;
                color: white;
                background-color: #293037;
                padding: 5px;
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                background-color: #282f37aa;
                z-index: 2;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &__infoP {
                color: #EA3F3F;
                margin-top: 10px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
            &__selectImage {
                width: 200px;
                height: 200px;
                border: 1px solid gray;
                margin: 0 auto;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &__wrap {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 99;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.696);
                    transition: opacity 0.3s ease;
                }
                &:hover > &__wrap {
                    opacity: 1;
                }
                & > img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}