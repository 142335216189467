.route__websiteRoutes {
    width: 100%;
    height: auto;
    overflow: auto;
    font-family: "Roboto";
    cursor: default;

    &__tablePill {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & > p {
            margin-right: 5px;
            color: #a4a4a4 !important;
        }
        & > span {
            padding: 4px 10px;
            border-radius: 5px;
            background-color: #3e4a58;
            font-size: 12px;
            margin-right: 5px;

            &.route__websiteRoutes__tablePill--secondary {
                background-color: #2a5e98;
            }
        }
    }
    &__addRoute {
        all: initial;
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        color: white;

        &__wrap {
            width: 700px;
            height: auto;
            max-height: 100vh;
            background: #282F37;
            border-radius: 10px;
            padding: 20px 18px;
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            overflow-y: auto;

            & * {
                flex-shrink: 0;
            }
            &__selectedWebsite {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                & > *:last-child {
                    margin-left: 15px;
                    height: 30px;
                }
            }
            &__spinner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #282f37c6;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s ease;
                z-index: 9;
            }
            &__btns {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
    
                & > p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -6px;
                        left: unset;
                        right: 0;
                        width: 0%;
                        height: 2px;
                        background-color: #2c7de3;
                        transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
                    }
                    &:last-child::after {
                        background-color: #EA3F3F;
                    }
                    &:hover::after {
                        right: unset;
                        left: 0;
                        width: 100%;
                    }
                    & > span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
            &__infoP {
                color: rgb(245, 74, 74);
                margin-top: 10px;
            }
            &__queryFilter {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;

                &__help {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }
                &__table {
                    box-shadow: 0px 0px 1px 1px rgba(92, 92, 92, 0.5);
                    margin-top: 20px;

                    .customComponents__table__head {
                        display: none;
                    }
                }
            }
        }
    }
}