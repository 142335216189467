@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #20272F;
  color: white;
}

#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr;
}

* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}