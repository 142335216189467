.component__heatmaps {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
        width: 100%;
        height: 100vh;
        background-color: #282f37;
        border-radius: 2px;
        padding:30px 20px 20px 20px;
        position: relative;
        display: flex;
        gap: 10px;
        &__close {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 28px;
            top: 10px;
            position: absolute;
            right: 20px;
            width: 40px;
        }
        &__devices {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            flex-wrap: wrap;
            flex-direction: column;
            & > p {
                padding: 10px 20px;
                border: 1px solid rgb(136, 136, 136);
                border-radius: 5px;
                cursor: pointer;
                width: 150px;
            }
        }
        &__preview {
            width: 100%;
            height: 100%;
            overflow: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;

            & > div {
                overflow: auto;
                margin: auto;
                position: relative;
                overflow: visible;
                background-color: white;
                margin: auto;
                & > iframe {
                    overflow: hidden;
                    &* {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
