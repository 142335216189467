.route__campaigns__disallows {
    width: 100%;
    height: auto;
    overflow: auto;
    font-family: "Inter";

    &__aInfo {
        font-weight: bold;

        & > span {
            color: #c4c0c0;
            font-weight: normal;
        }
    }
    & > .customComponents__input {
        margin-bottom: 20px;
        width: 100%;
    }
    & > .route__campaignsList__dateFilter {
        margin-bottom: 20px;
    }
}