.route__login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;

    &__wrap {
        width: 500px;
        height: 477px;
        background: #2F363E;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px;

        &__btn {
            width: 100%;
            height: 47px;
            margin-top: 50px;

            & > * {
                width: 100%;
                height: 100%;
            }
        }
        & > p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 35px;
            text-align: center;
            margin-bottom: 50px;
        }
        & &__infoP {
            color: #f77d7d;
            margin-top: 20px;
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}